const action = "https://web2bot-api.masxdesign.com/send.php"

function pre_middleware (formData, name) {
    switch (name) {
        case "contact":
            break
        case "newsletter":
        case "checkout":
            formData.set('request', name)
            break
    }
}

function post_middleware (data, name, form) {
    switch (name) {
        case "checkout":
        case "contact":
            window.location.href = data.success_page
            break
        case "newsletter":
            form.nextElementSibling.innerHTML = `
                <div class="text-center">
                    <h3 class="text-xl font-bold">Subscribed!</h3>
                    <p>Kindly reminder to check your inbox / spam</p>
                </div>
            `

            form.nextElementSibling.classList.remove("hidden")
            form.classList.add("hidden")


            break
    }
}

for(const form of document.querySelectorAll('form[data-name]')) {

    form.addEventListener("submit", async function (e) {
        e.preventDefault()

        const form = e.target

        const errors = form.querySelector(".errors")

        errors.textContent = ""
        errors.classList.add('hidden')

        const submitButton = form.querySelector('[type="submit"]')

        let prevButtonText = ""

        const elements = [...form.elements]
    
        try {

            let formData = new FormData(form)
    
            prevButtonText = submitButton.textContent
    
            form.classList.add('opacity-50')
            submitButton.textContent = 'Sending...'
    
            elements.forEach(element => {
                element.setAttribute('disabled', true)
            })

            pre_middleware(formData, form.dataset.name)

            const res = await fetch(action, {
                method: 'POST',
                body: formData
            })
            const data = await res.json()

            if (!data.success_page) throw new Error('server side error')

            submitButton.textContent = prevButtonText
            
            elements.forEach(element => {
                element.removeAttribute('disabled')
            })

            form.reset()

            post_middleware(data, form.dataset.name, form)
    
        } catch (e) {
    
            console.log(e.message)

            submitButton.textContent = prevButtonText

            elements.forEach(element => {
                element.removeAttribute('disabled')
            })

            errors.textContent = "Something happen. Please email us at web2bot@pannalabs.ai"
            errors.classList.remove('hidden')
            
        } finally {
            form.classList.remove('opacity-50')
        }
    })

}